import m from 'mithril'

import createModel from './model/index'
import createAction from './actions/index'
import createView from './view/index'

window['initRallySearch'] = initRallySearch
window['rallySearchComponentBasePath'] = _getCurrentFilePath()

_addCssPseudoStylePrototype()
_loadCssAndScript()


function initRallySearch(id, config = {}) {
    if (document.getElementById(id)) {
        let model = createModel(id, config)
            , actions = createAction(model)
            , view = createView()

        m.mount(document.getElementById(id), {
            view() {
                return view(model, actions)
            }
        })

        actions.configSearchBox()
    }
}

function _getCurrentFilePath() {
    let scripts = document.getElementsByTagName("script")
        , targetScript = Array.from(scripts).find(res => res['src'] && res.src.includes('wl-event-search'))
        , src = targetScript ? targetScript['src'] : ''
        , currentPath = src ? src.slice(0, src.lastIndexOf('/')) : window.location.origin; //(window.location.origin: for local)
    return currentPath
}

function _loadCssAndScript() {
    loadScript('https://unpkg.com/manuel@0.66.0/dist/manuel.js');
    loadCss(`${window['rallySearchComponentBasePath']}/css/style.css`);

    function loadScript(src) {
        let scriptElement = document.createElement('script')
        scriptElement.type = "application/javascript"
        scriptElement.src = src
        document.head.appendChild(scriptElement)
    }

    function loadCss(href) {
        let cssElement = document.createElement('link')
        cssElement.rel = 'stylesheet'
        cssElement.type = "text/css"
        cssElement.href = href
        document.head.appendChild(cssElement)
    }
}

function _addCssPseudoStylePrototype() {
    let UID = {
        _current: 0,
        getNew: function () {
            this._current++
            return this._current
        }
    };

    HTMLElement.prototype.pseudoStyle = function (element, prop, value) {
        let _this = this
            , _sheetId = "pseudoStyles"
            , _head = document.head || document.getElementsByTagName('head')[0]
            , _sheet = document.getElementById(_sheetId) || document.createElement('style')
            , className = "pseudoStyle" + UID.getNew();

        _sheet.id = _sheetId

        _this.className += " " + className

        _sheet.innerHTML += " ." + className + ":" + element + "{" + prop + ":" + value + "}"

        _head.appendChild(_sheet)

        return this
    }
}