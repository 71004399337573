import i18next from 'i18next';

import global_en from "../../i18n/i18n-en.json"
import global_pt from "../../i18n/i18n-pt.json"
import global_de from "../../i18n/i18n-de.json"

export default {
    init,
    changeLanguage
}

function init(langCode) {
    i18next.init({
        lng: langCode,
        debug: false,
        fallbackLng: ["en", "pt", "de"], 
        resources: {
            ['en']: {
                translation: getTranslation(global_en)
            },
            ['pt']: {
                translation: getTranslation(global_pt)
            },
            ['de']: {
                translation: getTranslation(global_de)
            }
        }
    })
}

function changeLanguage(language) {
    i18next.changeLanguage(language, (err, t) => {
        if (err) return console.log('Something went wrong changing language', err);
        t('key');
    });
}

function getTranslation(i18nData) {
    let obj = {}
    i18nData.data.forEach(({ key, val }) => {
        obj[key] = val || key
    })
    return obj
}