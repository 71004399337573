const defaultLaungageCode = 'en-US'

export const formattedDate = (date, languageCode = defaultLaungageCode) => {
  if (date) {
    let options = {
      year: 'numeric'
      , month: 'long'
      , day: 'numeric'
    }

    return new Intl.DateTimeFormat(languageCode, options).format(date)
  }
}