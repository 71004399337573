import getSettings from '/model/settings.js'

export default (id, config) => {
    var settings =  config.language !== undefined ? getSettings(config.language) : getSettings()

    return {
        id: id
        , settings: settings
        , type: ['bolide', 'user-event'].includes(config['type'])
            ? config['type'] : 'bolide'
        , manuel: {
            list: []
            , input: ''
            , chosen: ''
            , open: true
            , highlighted: { text: '' }
        }
        , items: []
        , nextUrl: ''
        , urlRallySearch: '/{selectedText}'

        , monthArray: 'Jan Feb March April May June July Aug Sep Oct Nov Dec'.split(' ')
        
        , prevCategory: ''
        , timeoutId: ''
        , lastChosen: ''
        , timeOutTime: 500

        , inputPlaceholder: config['placeholder']
        , defaultInputPlaceholderKey: 'search-placeholder'

        , theme: ['black', 'white'].includes(config['theme'])
            ? config['theme']
            : config['type'] == 'user-event' ? 'white' : 'black'

        , isShowDefaultSuggetion: config.hasOwnProperty('showDefaultCategory')
            ? config['showDefaultCategory']
            : true
    }
}