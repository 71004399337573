import m from 'mithril'
import { formattedDate } from '../utils/date'
import i18next from 'i18next'
import i18nManager from '../utils/i18n-mgr'

export default () => {
    let MithrilStreamAutocomplete = manuel({
        hyperscript: m
        , get: o => (o && o['text']) || o
        , set: (o, x) => {
            /// highlight case
            if (typeof o == 'object' && Object.keys(o) && Object.keys(o).length == 1) {
                o.text = x
            }
            return x
        }
    })

    return vwApp;

    function vwApp(model, actions) {
        i18nManager.init(model.settings.language)
        return m('div', {
            oninit: () => actions.afterSilence('')
        }, MithrilStreamAutocomplete(model.manuel, {
            maxItems: 500
            , oninput: (e) => actions.afterSilence(e.target.value)
            , filter: () => true
            , sort: (a, b) => actions.sort(a, b)
            , choose: (x) => actions.choose(x)
            , renderItem: (x, config) => {
                let categoryIndex = x.indexOf('~=')
                    , categoryName = x.substring(0, categoryIndex).split('~')[0]
                    , suggestionText = x.substring(categoryIndex + 2)
                    , data = actions.suggestionsByCategory(model.items)[categoryName].filter(d => d.Search1 == x)[0]
                    , isOtherCategory = model.prevCategory != categoryName;
                let test = i18next.t(categoryName, {});
                let regEx = new RegExp(`(${model.manuel.input})`, 'gi');
                suggestionText = suggestionText.replace(regEx, '<match>$1</match>');

                model.prevCategory = isOtherCategory ? categoryName : model.prevCategory

                let eventDate = (categoryName == 'event') ? new Date(data.EventDate) : false

                return [
                    m(`li.srchlist.${data.isDefault ? 'isDefault' : ''}.${isOtherCategory ? (data['className'] || categoryName) : ''}`, [
                        isOtherCategory && m('div.srchlist-head', test),
                        m('div.srchlist-detail', {
                            onmousedown() {
                                if (model.type == 'bolide') {
                                    window.open(model.urlRallySearch.replace('{selectedText}', data.ObjectKey), '_self')
                                } else {
                                    let customEvent = new CustomEvent('suggestionSelected', { detail: data });
                                    document.dispatchEvent(customEvent)
                                }
                                // Handling open popup on click event.
                                document.getElementsByClassName('search__dropdown') &&
                                    document.getElementsByClassName('search__dropdown')[0].classList.add('onclick__open');
                                if (document.getElementsByClassName('search__rally-ip__form')[0]) {
                                    let allChildren = document.getElementsByClassName('search__rally-ip__form')[0].children
                                    for (let i = 0; i < allChildren.length; i++) {
                                        allChildren[i].classList.add('__onclick__visible')
                                    }
                                }
                            }
                            , className: data.Search1 == model.manuel.highlighted.text ? 'highlight' : ''
                        }, [
                            m('.srchlist-detail-item', [
                                m.trust(suggestionText),
                                !data.isDefault && eventDate && m('span', `${formattedDate(eventDate, model.settings.languageCode)}, ${data['Search4'] || data['CityState']}`)
                            ])
                        ])
                    ])
                ]
            }
        }))
    }
}